/**
 * 菜单数据
 * @type {*[]}
 */
export const menuList = [
    // {
    //     icon:'ios-analytics',
    //     name:'仪表盘',
    //     visible:false,
    //     childNode:[]
    // },
    {
        icon:'icon-work-flow',
        name:'智能工作流',
        visible:false,
        menuCode:'site-inspection',
        childNode:[
            {
                name:'作业流程',
                link:'/workflow/process/list',
            },
            {
                name:'作业任务',
                link:'/workflow/task',
            },
            // {
            //     name:'作业任务',
            //     link:'/workflow/guidance/task',
            // },
            {
                name:'执行记录',
                link:'/workflow/record/list',
            },
        ]
    },
    {
        icon:'icon-device',
        name:'知识库',
        visible:false,
        menuCode:'knowledge-base-service',
        childNode:[
            {
                name:'设备知识库',
                link:'/device/list',
            }
        ]
    },
    {
        icon:'icon-user-center',
        name:'用户中心',
        visible:false,
        menuCode:'user-center',
        childNode:[
            {
                name:'账号管理',
                link:'/account',
            },
        ]
    },
]

/**
 * 系统菜单数据
 * @type {*[]}
 */
export const systemMenuList = [
    // {
    //     icon:'ios-analytics',
    //     name:'仪表盘',
    //     visible:false,
    //     childNode:[]
    // },
    {
        icon:'ios-home',
        name:'企业管理',
        link:'/business',
        visible:false,
        childNode:[
        ]
    }
]

/**
 * 上传类型
 * @type {{}}
 */
export const systemUploadType = {
    knowledge_image:'knowledge_image', //知识库图片
    conversation_session_image:'conversation_session_image', //远程指导图片
    inspection_node_image:'inspection_node_image', //安全巡检节点图片
    inspection_results_image:'inspection_results_image', //安全巡检结果图片
    inspection_results_voice:'inspection_results_voice', //安全巡检结果语音
    inspection_results_video:'inspection_results_video', //安全巡检结果录像
    user_avatar_image:'user_avatar_image', //用户头像图片
    inspection_annex:'inspection_annex',
    system_app:'system_app', //系统app版本
}

/**
 * 演示配置
 * @type {{DEMONSTRATE: string, NORMAL: string}}
 */
export const systemPublishEnum = {
    DEMONSTRATE:'DEMONSTRATE', // 演示无logo
    NORMAL:'NORMAL', //正常有logo
}

/**
 * 配置演示状态
 * @type {string}
 */
export const systemPublishLogo = systemPublishEnum.DEMONSTRATE
