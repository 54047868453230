const EVENT_NAME = {
    /**
     *  分类菜单列表
     */
    DEVICE_MENU_LIST:'DEVICE_MENU_LIST',
    /**
     * 可能的原因可显示
     */
    REASON_MODAL_VISIBLE:'REASON_MODAL_VISIBLE',
    /**
     * 进度条数值
     */
    UPLOAD_PROGRESS_COMPLETE:'UPLOAD_PROGRESS',
}
export default EVENT_NAME
